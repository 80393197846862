import React, { useState, useEffect, useRef } from "react";
import { getSiteSettings, saveSiteSettings } from "../services/consts";
import OurHeader from "./OurHeader";
import Toast from "./Toast";

import { ChromePicker } from "react-color";

function ColorPicker(props) {
  const [color, setColor] = useState(props.color ? props.color : "#000000");
  const [showPicker, setShowPicker] = useState(false);

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
    props.onChoose(newColor.hex);
  };

  const handleChooseColor = (newColor) => {
    props.onChoose(newColor.hex);
  };

  return (
    <div style={{ display: "inline-flex" }}>
      <button class="btn btn-primary" onClick={() => setShowPicker(true)}>
        Choose Color
      </button>
      <div
        style={{
          width: "24px",
          height: "24px",
          borderRadius: "4px",
          marginLeft: "8px",
          border: "1px solid #000",
          backgroundColor: color,
        }}
      />
      {showPicker && (
        <>
          <div style={{ position: "absolute", zIndex: 2 }}>
            <ChromePicker color={color} onChange={handleColorChange} />
          </div>
          <div
            onClick={() => setShowPicker(false)}
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          />
          <button class="btn btn-primary" onClick={handleChooseColor}>
            Select
          </button>
        </>
      )}
    </div>
  );
}

function Settings(props) {
  const [siteSettings, setSiteSettings] = useState(null);
  const [showToast, setShowToast] = useState(false);

  const loadUserSettings = async () => {
    const settings = await getSiteSettings();
    if (settings) {
      setSiteSettings(settings);
    }
  };

  const handleSaveSettings = async () => {
    const result = await saveSiteSettings(siteSettings);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };

  useEffect(() => {
    loadUserSettings();
  }, []);

  return (
    <div className="container">
      <OurHeader history={props.history}></OurHeader>
      <div className="row mt-5">
        <div className="col-lg-6 offset-lg-3">
          <h1 className="text-center mb-2">Customize Settings</h1>
          <p
            style={{
              padding: "10px",
              border: "1px solid #f44336",
              backgroundColor: "#ffebee",
              color: "#f44336",
              fontWeight: "bold",
            }}
          >
            Note that the colors only update on the customer view. To test the
            changes you've made, simply open the customer view in an incognito
            window. However, please note that the new color settings are cached
            for the user for 10 minutes, meaning that the application will not
            check for new colors until then. If you need to test the color
            settings before the 10-minute cache expires, you can either use an
            incognito window, close and reopen it, or clear your browser cache
            before reloading the customer view.
          </p>
          {siteSettings && (
            <>
              <div className="card mb-2">
                <div className="card-body">
                  <h5 className="card-title">Background Color</h5>
                  <ColorPicker
                    color={siteSettings?.background || "#fff"}
                    onChoose={(color) =>
                      setSiteSettings({ ...siteSettings, background: color })
                    }
                  />
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-body">
                  <h5 className="card-title">Text Color</h5>
                  <ColorPicker
                    color={siteSettings?.text || "#000"}
                    onChoose={(color) =>
                      setSiteSettings({ ...siteSettings, text: color })
                    }
                  />
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-body">
                  <h5 className="card-title">Button Color</h5>
                  <ColorPicker
                    color={siteSettings?.button || "#000"}
                    onChoose={(color) =>
                      setSiteSettings({ ...siteSettings, button: color })
                    }
                  />
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-body">
                  <h5 className="card-title">Header Background Color</h5>
                  <ColorPicker
                    color={siteSettings?.headerBackground || "#000"}
                    onChoose={(color) =>
                      setSiteSettings({
                        ...siteSettings,
                        headerBackground: color,
                      })
                    }
                  />
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-body">
                  <h5 className="card-title">Header Title Color</h5>
                  <ColorPicker
                    color={siteSettings?.headerTitle || "red"}
                    onChoose={(color) =>
                      setSiteSettings({ ...siteSettings, headerTitle: color })
                    }
                  />
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-body">
                  <h5 className="card-title">Header Link Color</h5>
                  <ColorPicker
                    color={siteSettings?.headerLink || "white"}
                    onChoose={(color) =>
                      setSiteSettings({ ...siteSettings, headerLink: color })
                    }
                  />
                </div>
              </div>
            </>
          )}

          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary"
              onClick={() => handleSaveSettings()}
            >
              Save Settings
            </button>
          </div>
          {showToast && <Toast text="Settings saved!" />}
        </div>
      </div>
    </div>
  );
}
export default Settings;
