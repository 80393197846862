import React, { useState, useEffect } from "react";

function Toast(props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);

    const timer = setTimeout(() => {
      setShow(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return show ? (
    <div
      style={{
        position: "fixed",
        bottom: "1rem",
        right: "1rem",
        backgroundColor: "#333",
        color: "#fff",
        padding: "1rem",
        borderRadius: "0.25rem",
      }}
    >
      {props.text}
    </div>
  ) : null;
}

export default Toast;
