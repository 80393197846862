import React, { useState, useEffect, useRef } from "react";
import { api_base, loadUsers, getSiteSettings } from "../services/consts";
import OurHeader from "./OurHeader";
import GoogleMapReact from "google-map-react";

function Stats(props) {
  const [offsetType, setOffsetType] = useState("perc");
  const [offset, setOffset] = useState(0);
  const [offlineListDate, setOfflineListDate] = useState("");
  const [siteSettings, setSiteSettings] = useState(null);

  const handleOffsetChange = (e) => {
    const offset = e.target.value;
    setOffset(offset);
  };

  const saveSettings = () => {
    localStorage["usersettings"] = JSON.stringify({
      offsetType: offsetType,
      offset: offset,
    });
    alert("Settings Saved");
  };

  const loadUserSettings = () => {
    let settings;
    localStorage["usersettings"]
      ? (settings = JSON.parse(localStorage["usersettings"]))
      : (settings = { offset: 0 });

    setOffset(settings.offset);
    setOffsetType(settings.offsetType);

    const offlinelist = localStorage["offlinelist"]
      ? JSON.parse(localStorage["offlinelist"])
      : [];

    if (offlinelist === undefined || offlinelist.date !== undefined) {
      setOfflineListDate(offlinelist.date);
    }
  };

  const loadSiteSettings = async () => {
    const settings = await getSiteSettings();
    if (settings) {
      setSiteSettings(settings);
    }
  };

  useEffect(() => {
    loadUserSettings();
    loadSiteSettings();
  }, []);

  if (!siteSettings) {
    return <h1>Loading . . .</h1>;
  }

  return (
    <div
      style={{
        backgroundColor: siteSettings.background,
        minHeight: "100vh",
      }}
    >
      <OurHeader history={props.history}></OurHeader>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form style={{ width: 400, marginTop: 150 }}>
          <h6>Last Pricelist Fetched: {offlineListDate}</h6>
          <div class="form-group">
            <div class="btn-group" role="group">
              {offsetType === "perc" ? (
                <button type="button" class="btn btn-primary">
                  Percentage
                </button>
              ) : (
                <button
                  //onClick={}
                  type="button"
                  onClick={() => setOffsetType("perc")}
                  class="btn btn-secondary"
                >
                  Percentage
                </button>
              )}

              {offsetType === "fixed" ? (
                <button type="button" class="btn btn-primary">
                  Fixed
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => setOffsetType("fixed")}
                  class="btn btn-secondary"
                >
                  Fixed
                </button>
              )}
            </div>
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Offset</label>
            <input
              value={offset}
              onChange={handleOffsetChange}
              type="text"
              class="form-control"
            />
          </div>

          <button
            type="button"
            onClick={() => saveSettings()}
            style={{ backgroundColor: siteSettings.button }}
            class="btn btn-primary"
          >
            Save changes
          </button>
        </form>
      </div>
    </div>
  );
}
export default Stats;
