import React, { useState, useEffect } from "react";
import { login, getSiteSettings } from "../services/consts";
import { config } from "../services/installConfig";

function OurHeader(props) {
  const [user, setUser] = useState({ username: "" });
  const [isAdmin, setIsAdmin] = useState(false);
  const [hasUser, setHasUser] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [siteSettings, setSiteSettings] = useState(null);

  const loadUser = () => {
    if (localStorage["userinfo"]) {
      const user = JSON.parse(localStorage["userinfo"]);
      if (localStorage["userinfo"]) {
        const userinfo = login(user.username, user.password, (receivedUser) => {
          if (receivedUser !== null) {
            setUser(receivedUser);
            setIsAdmin(receivedUser.admin || false);
            setHasUser(true);
            if (!receivedUser.admin && window.location.pathname === "/") {
              props.history.push("/search");
            } else {
              if (window.location.pathname === "/") {
                props.history.push("/manage-users");
              }
            }
            localStorage["userinfo"] = JSON.stringify(receivedUser);
          } else {
            localStorage.removeItem("userinfo");
            return false;
          }
        });
      } else {
        props.history.push("/");
      }
    } else {
      props.history.push("/");
    }
  };

  const toggleNavbar = () => {
    const toggled = showNavbar;
    setShowNavbar(!toggled);
  };

  const loadSiteSettings = async () => {
    const settings = await getSiteSettings();
    if (settings) {
      setSiteSettings(settings);
    }
  };

  useEffect(() => {
    loadUser();
    loadSiteSettings();
  }, []);

  const logOut = () => {
    localStorage.removeItem("userinfo");
    window.location.reload();
  };

  if (!siteSettings) {
    return <h1>...</h1>;
  }

  if (!hasUser) {
    return (
      <div>
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
          <a class="navbar-brand" href="/home">
            {config.appName}
          </a>
        </nav>
      </div>
    );
  }

  if (isAdmin) {
    return (
      <div>
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
          <a class="navbar-brand" href="/home">
            {config.appName}
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleNavbar}
          >
            <span class="navbar-toggler-icon" style={{ color: "#fff" }}>
              toggle
            </span>
          </button>

          <div
            class="collapse navbar-collapse"
            style={{
              display: showNavbar ? "block" : "none",
            }}
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav mr-auto">
              <li>
                <a class="nav-link">Version: {config.version}</a>
              </li>
              <li class="nav-item active">
                <a class="nav-link" href="/">
                  Home
                </a>
              </li>
              {config.stats && (
                <li class="nav-item">
                  <a class="nav-link" href="/stats">
                    Stats
                  </a>
                </li>
              )}

              <li class="nav-item">
                <a class="nav-link" href="/manage-users">
                  Manage Users
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/manage-pricelists">
                  Manage Pricelists
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/admin-settings">
                  Site Settings
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" onClick={() => logOut()}>
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  } else {
    return (
      <div>
        <nav
          class="navbar navbar-expand-lg "
          style={{ backgroundColor: siteSettings?.headerBackground || "black" }}
        >
          {/* <p>{JSON.stringify(siteSettings)}</p> */}
          <img
            style={{ maxWidth: "100px", marginTop: 10 }}
            src={"/images/" + config.homeImg}
          />
          <a
            class="navbar-brand"
            href="/home"
            style={{ color: siteSettings?.headerTitle || "red" }}
          >
            {config.appName}
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleNavbar}
          >
            <span class="navbar-toggler-icon" style={{ color: "#fff" }}>
              ...
            </span>
          </button>

          <div
            class="navbar-collapse"
            style={{
              display: showNavbar ? "block" : "none",
            }}
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav mr-auto">
              <li class="nav-item">
                <a
                  class="nav-link"
                  style={{ color: siteSettings?.headerLink || "#fff" }}
                  href="/search"
                >
                  Search
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  style={{ color: siteSettings?.headerLink || "#fff" }}
                  href="/user-settings"
                >
                  Settings
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  style={{ color: siteSettings?.headerLink || "#fff" }}
                  onClick={() => logOut()}
                >
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
export default OurHeader;
